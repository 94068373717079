<template>
  <div class="maincontent bg_darkest text_white" :class="theme">
    <div class="row m-0 h-100">
      <div class="col-12 p-0 h-100">
        <div class="row m-0 align-items-center h-100 overflow_y">
          <div class="col-12 p-0 h-100">
            <div class="mobile_menu py-5 mt-5 px-4 overflow_y" v-if="showMenu">
              <mobile-menu @openPage="openPage" :selectedMenuItem="selectedMenuItem"></mobile-menu>
            </div>
            <div class="row m-0 p-0" v-else-if="showModalTAndC">
              <div class="col-12 p-0">
                <t-and-c @close="$emit('toggleModalTAndC')" :isDesktop="false"></t-and-c>
              </div>
            </div>
            <div class="row m-0 p-0" v-else-if="currentPage">
              <div class="col-12 p-0" v-if="selectedMenuItem.title">
                <custom-header :page='currentPage' :subPage='currentSubPage' :selectedMenuItem="selectedMenuItem" :isDesktop="false"></custom-header>
              </div>
              <div class="col-12 text-center font2 pt-3">
                <details-page v-if="currentPage === 'details'" :detailsActivePage="currentSubPage" @changeActivePage="changeActivePage" @changeSubPage="changeSubPage"></details-page>
                <documents-page v-if="currentPage === 'docs'" :documentsActivePage='currentSubPage'></documents-page>
                <planner-page v-if="currentPage === 'planner'"></planner-page>
                <social-media-page v-if="currentPage === 'socialMedia'"></social-media-page>
              </div>
            </div>
            <div class="row m-0 align-items-center h-100 maincontent" v-else>
              <div class="col-12 text-center font2">
                My personal MORE Family<br>
                Collection profile
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMenuItem } from '@/helpers/menu'
export default {
  name: 'MobileMainPage',
  components: {
    'custom-header': () => import('@/components/Header.vue'),
    'details-page': () => import('@/pages/DetailsPage.vue'),
    'documents-page': () => import('@/pages/DocumentsPage.vue'),
    'mobile-menu': () => import('@/components/mobile/Menu.vue'),
    'planner-page': () => import('@/pages/PlannerPage.vue'),
    'social-media-page': () => import('@/pages/SocialMediaPage.vue'),
    't-and-c': () => import('@/components/modals/TAndC')

  },
  props: [
    'theme',
    'showMenu',
    'showModalTAndC'
  ],
  data () {
    return {
      currentPage: null,
      currentSubPage: null,
      selectedMenuItem: null,
      showSubMenu: true
    }
  },
  methods: {
    changeSubPage (val) {
      this.currentSubPage = val
    },
    changeActivePage (val) {
      this.selectedMenuItem = getMenuItem(val)
      this.$emit('toggleMenu')
    },
    openPage (menuItems) {
      this.selectedMenuItem = menuItems[0]
      this.currentPage = menuItems[0].value
      if (menuItems.length > 1) {
        this.currentSubPage = menuItems[menuItems.length - 1].value
      } else {
        this.currentSubPage = null
      }
      this.$emit('toggleMenu')
    }
  }
}
</script>
<style scoped>
.maincontent {
  width: 100%;
  min-height: calc(100vh - 240px);
  /* position: absolute; */
  right: 0;
  background-size: cover;
  background-position: center center;
}
.page_content {
  height: 100%;
}
.page_content.has_header {
  padding-top: 55px;
}
.page_content.has_subheader {
  padding-top: 80px;
}
.abs_top {
  position: absolute;
  top: 0;
  z-index: 10;
}
.abs_bottom {
  position: absolute;
  bottom: 1.5rem;
  z-index: 10;
}
.beach {
  background-image: url(../../assets/images/bg_beach.png);
  background-position: 0 bottom !important;
}
.bush {
  background-image: url(../../assets/images/bg_bush.png);
  /* background-position: 550px bottom !important; */
}
.city {
  background-image: url(../../assets/images/bg_city.png);
  background-position: 550px bottom !important;
}
.mountain {
  background-image: url(../../assets/images/bg_mountain.png);
  background-position: 480px bottom !important;
}
@media (min-width: 768px) {
  .page_content.has_header {
    padding-top: 85px;
  }
  .page_content.has_subheader {
    padding-top: 125px;
  }
}
@media (min-width: 992px) {
  .maincontent {
    height: 100vh;
    width: 70vw;
    position: absolute;
    right: 0;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    overflow-y: hidden;
  }
  .page_content {
    height: 100vh;
    overflow-y: auto;
  }
  .page_content.has_header {
    padding-top: 115px;
  }
  .page_content.has_subheader {
    padding-top: 165px;
  }
  .mpt_logo_page {
    width: 165px;
    height: 152px;
  }
  .back_btn {
    width: 20vw;
    cursor: pointer;
    padding-left: 4rem;
  }
  .back_btn_submenu_open {
    padding-left: 26rem;
  }
}
</style>
